.hwx-footer {
    .hwx-footer-content {
        .hwx-opening-times {
            @include margin-bottom($base-font-size);
        }

        .hwx-footer-logos {
            @include make-spacing($grid-gutter-width-half);
            @include margin-top($grid-gutter-width-half);
            display         : flex;

            @include media-breakpoint-down(md) {
                flex-wrap : wrap;
            }

            @include media-breakpoint-up(lg) {
                @include margin-top(50);
                justify-content : space-between;
            }

            .hwx-footer-logo {
                flex      : 1 0 33%;
                max-width : 33%;

                @include media-breakpoint-between(sm, md) {
                    flex-basis : 25%;
                    max-width  : 25%;
                }

                @include media-breakpoint-up(lg) {
                    flex-basis : auto;
                    max-width  : none;
                }

                img {
                    @include height(50);
                }
            }
        }
    }
}