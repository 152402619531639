div.cc-window {
    .cc-message {
        line-height : 1.3;

        a {
            color : $white;
        }
    }

    div.cc-compliance {
        a.cc-btn.cc-deny {
            display : none;
        }

        a.cc-btn.cc-allow {
            border           : 1px solid $white;
            background-color : transparent;
        }
    }
}

.cc-revoke.cc-center.cc-revoke-bottom {
    @include rem(left, 50);
    margin-left : auto;

    .fa-cookie {
        @include font-size-rem(24);
    }
}