@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function addUnit($value, $unit: 1px) {
    @if ($value and unitless($value)) {
        $value : $value * $unit;
    }

    @return $value;
}

@function headings($from:1, $to:6) {
    @if $from == $to {
        @return 'h#{$from}';
    } @else {
        @return 'h#{$from},' + headings($from+1, $to);
    }
}

@function rem($pixels, $context: 16px) {
    @if ($pixels and $context) {
        @return addUnit($pixels) / addUnit($context) * 1rem;
    }

    @return null;
}