footer#footer {
    .footer-top {
        @include padding-top(20);
        @include padding-bottom(20);
        background-color: $blue;
        color: $white;

        a {
            color: $white;

            @include hover-focus-active {
                color: darken($white, 10%);
            }
        }

        @include media-breakpoint-up(md) {
            @include padding-top(30);
            @include padding-bottom(30);
        }

        @include media-breakpoint-up(xl) {
            @include padding-top(50);
            @include padding-bottom(50);
        }

        #hwx-footer-122, #hwx-footer-145 {
            .hwx-footer-col {
                .hwx-footer-content {
                    .hwx-opening-times {
                        table {
                            tbody {
                                tr {
                                    td {
                                        padding-right: 5px;
                                    }
                                    td:nth-child(3) {
                                        padding-left: 20px;
                                    }
                                    td[colspan='2'] {
                                        position: relative;
                                        small {
                                            position: absolute;
                                            top: -5px;
                                            width: max-content;
                                        }
                                    }
                                    @include media-breakpoint-down(sm) {
                                        font-size: 0.9rem;
                                    }
                                }
                            }
                        }
                    }
                    .row {


                        .hwx-footer-logo {
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            @include media-breakpoint-down(md) {
                                text-align: center;
                            }

                            img {
                                @include media-breakpoint-down(md) {
                                    width: 55%;
                                    padding: 16px 0;
                                }
                            }
                        }

                        .hwx-footer-logo:last-child {
                            img {
                                width: 35%;
                                border: 5px double white;
                                display: flex;
                                margin-top: 35px;
                                margin-left: auto;
                                margin-right: auto;
                                margin-bottom: 0;

                                @include media-breakpoint-down(md) {
                                    width: 100%;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-nav-top {


    }

    .footer-main {
        @include padding-top(20);
        @include padding-bottom(20);
        background-color: $dark-blue;

        .footer-left-container {

        }

        .footer-middle-container {

        }

        .footer-right-container {

            a {
                color: $white;

                @include hover-focus-active {
                    color: darken($white, 15%);
                }
            }
        }
    }

    .footer-nav-bottom {

    }

    .footer-bottom {

    }
}