//
// Font Settings
//
$font-weight-thin : 100;
$font-weight-extralight : 200;
$font-weight-light : 300;
$font-weight-normal : 400;
$font-weight-regular : $font-weight-normal;
$font-weight-medium : 500;
$font-weight-semibold : 600;
$font-weight-bold : 700;
$font-weight-extrabold : 800;
$font-weight-black : 900;

// Wird für die font-face Generation vom Template verwendet
// Nicht verändern!!! Die Schreibweise des keys ist wichtig, da die Bezeichnung im Pfad Verwendung findet.
$font-weights : (
        "Thin" : $font-weight-thin,
        "ExtraLight" : $font-weight-extralight,
        "Light" : $font-weight-light,
        "Regular" : $font-weight-regular,
        "Medium" : $font-weight-medium,
        "SemiBold" : $font-weight-semibold,
        "Bold" : $font-weight-bold,
        "ExtraBold" : $font-weight-extrabold,
        "Black" : $font-weight-black
);