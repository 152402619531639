#hwx-default-category {
    .hwx-default-categoryblock {

    }

    .hwx-bueroservice-subcategories {
        @include margin-bottom(50);

        & + hr {
            width        : 66%;
            margin-left  : auto;
            margin-right : auto;
        }

        & > .row {
            @include make-vertical-spacing($grid-gutter-width-half);
        }

        .hwx-subcategory-item {
            position : relative;

            .hwx-subcategory-item-header {
                position : absolute;
                top      : 0;
                right    : 0;
                left     : 0;
                bottom   : 0;

                a {
                    display         : flex;
                    align-items     : center;
                    text-decoration : none;
                    height          : 100%;
                }

                .hwx-subcategory-item-header-text {
                    @include padding(20);
                    background-color : rgba($primary, 0.3);
                    font-weight      : $font-weight-bold;
                    margin           : 0;
                    color            : $white;
                    text-align       : center;
                    width            : 100%;
                    text-shadow      : 0 0 15px black;

                    @include media-breakpoint-only(sm) {
                        @include font-size-rem(24);
                    }
                }
            }
        }
    }


    .hwx-default-category-body {
        @include margin-top(50);

        & > .row {
            @include make-vertical-spacing(20);
        }

        .hwx-default-category-item-container {
            .hwx-default-category-item-body {
                .hwx-itemlist-header {
                    @include height(80);
                    @include margin-bottom(20);
                    overflow : hidden;

                    .hwx-itemlist-header-text {
                        margin-bottom      : 0;
                        /*
                         * Das muss so sein, damit die Überschrift nach zwei Zeilen abgebrochen wird
                         * Das overflow: hidden im elterncontainer ist ebenfalls wichtig.
                         */
                        display            : -webkit-box;
                        -webkit-line-clamp : 2;
                        -webkit-box-orient : vertical;
                    }
                }

                .hwx-itemlist-imageblock {
                    @include margin-bottom(30);
                }

                .hwx-itemlist-introtext {
                    h3 {
                        font-size : 130%;
                    }

                    h4 {
                        font-size : 120%;
                    }

                    h5 {
                        font-size : 115%;
                    }

                    h6 {
                        font-size : 110%;
                    }
                }
            }

            .hwx-itemlist-readmore {
                text-align : right;

                a {
                    text-decoration : none;
                }
            }
        }
    }
}

#hwx-default-item {
    .hwx-item-header {
        .hwx-item-header-text {

        }
    }

    .hwx-default-item-body {
        .hwx-item-imageblock {

        }

        .hwx-item-introtext {

        }

        .hwx-item-attachments {

        }
    }

    .hwx-item-videoblock {

    }

    .hwx-item-gallery {

    }
}