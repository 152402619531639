div.rsform {
    form {
        div#rsform-formtitle {
            h2 {
                @include rem-multivalue(padding, 10, 0);

                margin-bottom : 0;
            }
        }

        div#rsform-error {
            @include rem-multivalue(padding, 10, 0);

            margin-bottom : 0;

            p {
                margin-bottom : 0;
            }
        }

        fieldset {
            div.row {
                @include rem-multivalue(padding, 10, 0);
            }
        }
    }

    input.rsform-submit-button,
    button#Send {
        @include primary-button();
        @include rem-multivalue(padding, 8, 12);

        background-image : none;
        border           : none;
        text-shadow      : none;
    }
}