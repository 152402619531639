/**
Beispiel:
in dem templates/hwxtemplate/fonts Ordner liegt
WorkSans/WorkSans-Black
WorkSans/WorkSans-BlackItalic
WorkSans/WorkSans-Bold
WorkSans/WorkSans-BoldItalic
WorkSans/WorkSans-Light

und

Roboto/Roboto-Regular
Roboto/Roboto-Black
Roboto/Roboto-Light

dann:
(Achtung, WorkSans am Ende des Pfades markiert den Namen der Schrift ohne Schnitte)
"WorkSans" : (
font-path: "../fonts/WorkSans/WorkSans",
font-extensions: (ttf),
font-weights: ("ExtraLight", "Light", "Regular", "SemiBold", "Bold", "Black"),
font-italic-weights: null,
),
"Roboto" : (
                font-path: "../fonts/Roboto/Roboto",
                font-extensions: (ttf, woff, woff2),
                font-weights: ("Regular", "Bold"),
                font-italic-weights: ("Light", "Regular", "SemiBold"),
)

Weiterer Hinweis:
$template-font-family: "Work Sans" gibt die Hauptschriftart an.

weitere Schriftarten können als Variable gespeichert werden, z.B.
$font-family-second : "Roboto";
Es geht aber auch auf dem herkömmlichen Wege via css style font-family: "Roboto"

Achtung:
Wenn man Schriftarten herunterlädt kommt es häufig vor, dass z.B. nur Roboto-Italic da steht. Das ist dann
Roboto-RegularItalic
*/

$template-font-family   : "Open Sans";
$template-fonts         : (
        "Open Sans" : (
                font-path: "../fonts/OpenSans/OpenSans",
                font-extensions: (ttf, woff, woff2),
                font-weights: ("Bold", "ExtraBold", "Light", "Regular", "SemiBold"),
                font-italic-weights: ("Bold", "ExtraBold", "Light", "Regular", "SemiBold"),
        ),
);

$font-family-sans-serif : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base       : $template-font-family, $font-family-sans-serif;