.hwx-item-imagetitleblock,
.hwx-itemlist-imagetitleblock {
    position : relative;
    
    .hwx-item-image {
        &.hwx-as-background {
            background-position : center;
            background-repeat   : no-repeat;
            display             : flex;
            justify-content     : center;
            align-items         : center;
        }
        
        img {
            width : 100%;
        }
        
    }
    
    .hwx-item-title {
        position        : absolute;
        top             : 0;
        right           : 0;
        bottom          : 0;
        left            : 0;
        display         : flex;
        justify-content : center;
        align-items     : center;
    }
    
    .hwx-item-image-text {
        @include rem-multivalue(padding, 15);
        margin           : 0;
        color            : $white;
        background-color : rgba($black, 0.6);
    }
}