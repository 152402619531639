#redim-cookiehint {
    @include padding(10);
    @include zindex(cookiehint);
    @include font-size-rem(14);
    background-color : $primary;
    display          : flex;
    color            : $white;
    justify-content  : center;
    align-items      : center;
    position         : fixed;
    width            : 100%;
    top              : 0;
    left             : 0;

    .cookiecontent {
        @include padding-left(15);
        @include padding-right(15);
        flex : 1;

        p {
            margin : 0;
        }
    }

    .cookiebuttons {
        .btn {
            background-color : $white;
            color            : $black;
            border-radius    : 0;
        }
    }
}