$white                         : #ffffff;
$gray-100                      : #f8f9fa;
$gray-200                      : #e9ecef;
$gray-300                      : #dee2e6;
$gray-400                      : #ced4da;
$gray-500                      : #adb5bd;
$gray-600                      : #6c757d;
$gray-700                      : #495057;
$gray-800                      : #343a40;
$gray-900                      : #212529;
$black                         : #000000;
$facebook-blue                 : #4267b2;
$facebook-white                : #ffffff;
$twitter-blue                  : #1da1f2;
$twitter-white                 : #ffffff;
$youtube-red                   : #ff0000;
$youtube-white                 : #ffffff;

$red                           : #da121a;
$light-red                     : lighten($red, 10%);
$blue                          : #425881;
$dark-blue                     : #2a3852;

$primary                       : $red;
$secondary                     : $blue;

$hwx-menu-sub-menu-arrow-color : $black;
$back-to-top-foreground        : $white;

//$link-color            : $primary;

$link-decoration               : underline;
$link-hover-decoration         : underline;

$btn-border-radius             : 0;
$btn-border-radius-lg          : 0;
$btn-border-radius-sm          : 0;

//$navbar-padding-x      : 0;
$navbar-padding-y              : 0;

$nav-link-height-xs            : 60;
$nav-link-height-md            : 80;
$nav-link-height-xl            : 80;