.hwx-smallbox {
    @include media-breakpoint-down(sm) {
        @include margin-bottom(30);
    }
    
    p {
        margin : 0;
    }
    
    .hwx-smallbox-container {
        .hwx-smallbox-head {
            position : relative;
            
            &.has-bg-img {
                height              : 200px;
                background-size     : cover;
                background-repeat   : no-repeat;
                background-position : center;
            }
            
            img {
                width : 100%;
            }
            
            .hwx-smallbox-title-container {
                @include padding-left(20);
                @include padding-right(20);
                @include height(125);
                width            : 85%;
                position         : absolute;
                top              : 0;
                left             : 0;
                right            : 0;
                margin           : 0 auto;
                background-color : rgba($secondary, 0.9);
                display          : flex;
                align-items      : center;
    
                @include media-breakpoint-only(md) {
                    @include height(100);
                }
                
                .hwx-smallbox-title {
                    @include font-size-rem(24);
                    margin         : 0;
                    text-align     : center;
                    color          : $white;
                    text-transform : uppercase;
                    width          : 100%;
                    
                    @include media-breakpoint-only(md) {
                        @include font-size-rem(18);
                    }
                }
            }
        }
        
        .hwx-smallbox-body {
            @include margin-top(25);
            @include margin-bottom(25);
            margin-left  : auto;
            margin-right : auto;
            width        : 85%;
        }
    }
}