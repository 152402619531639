@each $_font-family, $_font-settings in $template-fonts {
    $_font-path : map-get($_font-settings, font-path);
    $_font-extensions : map-get($_font-settings, font-extensions);
    $_font-weights : map-get($_font-settings, font-weights);
    $_font-italic-weights : map-get($_font-settings, font-italic-weights);
    
    //@debug $_font-family;
    //@debug "path: #{$_font-path}";
    //@debug "extensions: #{$_font-extensions}";
    //@debug "weights: #{$_font-weights}";
    //@debug "italic-weights: #{$_font-italic-weights}";
    
    @each $_font-weight in $_font-weights {
        @include font-face($_font-family, "#{$_font-path}-#{$_font-weight}", map-get($font-weights, $_font-weight), normal, $_font-extensions);
        
        @if (index($_font-italic-weights, $_font-weight) != null) {
            @include font-face($_font-family, "#{$_font-path}-#{$_font-weight}Italic", map-get($font-weights, $_font-weight), italic, $_font-extensions);
        }
    }
}