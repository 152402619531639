.hwx-empfohlen-module {
    @include margin-bottom(50);

    .hwx-empfohlen-items {
        @include make-spacing($grid-gutter-width-half);
        display   : flex;
        flex-wrap : wrap;

        .hwx-empfohlen-item {
            flex           : 1 0 33%;
            max-width      : 50%;
            display        : flex;
            flex-direction : column;

            @include media-breakpoint-only(xs) {
                max-width  : 100%;
                flex-basis : 100%;
            }

            .hwx-empfohlen-item-body {
                height : 100%;

                .hwx-empfohlen-item-title {
                    .hwx-empfohlen-item-title-text {
                        @include height(66);
                        overflow : hidden;

                        a {
                            /*
                             * Das muss so sein, damit die Überschrift nach zwei Zeilen abgebrochen wird
                             * Das overflow: hidden im elterncontainer ist ebenfalls wichtig.
                             */
                            display            : -webkit-box;
                            -webkit-line-clamp : 2;
                            -webkit-box-orient : vertical;
                        }
                    }
                }

                .hwx-empfohlen-item-text {
                    @include margin-bottom(15);
                }
            }
        }
    }
}