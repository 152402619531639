#hwx-lmdb-termine-list {
    &.hwx-lmdb-list-tiles {
        .hwx-lmdb-list-item {
            @include font-size(15px);
            background-color : rgba($primary, 0.05);

            .hwx-lmdb-list-item-image {
                @include height(180px);
                overflow : hidden;

                img {
                    width           : 100%;
                    height          : 100%;
                    object-fit      : cover;
                    object-position : center center;
                }
            }

            .hwx-lmdb-list-item-content {
                @include padding(15px);

                .hwx-lmdb-list-item-content-details {
                    @include rem(gap, 5px);
                    color          : $gray-700;
                    font-style     : italic;
                    flex-direction : column;
                    flex-wrap      : nowrap;

                    .hwx-lmdb-list-item-content-date {
                        font-weight : $font-weight-semibold;
                    }
                }

                .hwx-lmdb-list-item-content-title {
                    .hwx-lmdb-list-item-content-title-text {
                        @include font-size(18px);
                        font-weight : $font-weight-semibold;
                    }
                }
            }
        }
    }
}
