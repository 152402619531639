main#content {
    #content-banner-top {
        display   : flex;
        flex-flow : column;
        position  : relative;

        nav#hwx-main-menu-banner-nav {
            position : absolute;
            bottom   : 0;
            left     : 0;
            right    : 0;
            margin   : auto;
            z-index  : 10;
            order    : 2;

            @include media-breakpoint-down(sm) {
                position : unset;
                margin   : unset;
            }

            div#navbar-121 {
                ul#hwx-main-menu-banner-ul {

                    @include media-breakpoint-down(sm) {
                        display : block;
                    }

                    li {
                        @include media-breakpoint-down(sm) {
                            position      : relative;
                            border-bottom : 2px solid white;
                        }

                        a {
                            span {
                                @include media-breakpoint-down(sm) {
                                    position : absolute;
                                    top      : 15px;
                                    left     : 35px;
                                    bottom   : 0;
                                    margin   : auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        #hwx-carousel-131 {
            order : 1;

            @include media-breakpoint-down(md) {
                display : none;
            }
        }

        div.custom {

            div.svg-link {
                z-index         : 20;
                position        : absolute;
                right           : 0;
                top             : 0;
                bottom          : 0;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
                width           : 65px;

                @include media-breakpoint-down(md) {
                    width : 50px;
                }

                a {
                    padding : 0;

                    svg {
                        color            : white;
                        background-color : #000000b3;
                        font-size        : 55px;
                        padding          : 12px 15px;
                    }

                    svg:hover {
                        color            : white;
                        background-color : #da121ac2;
                        font-size        : 55px;
                        padding          : 12px 0;
                        width            : 100px;
                    }

                    svg:first-child {
                        width       : 100%;
                        padding-top : 15px;
                    }

                    svg:last-child {
                        width          : 100%;
                        padding-bottom : 15px;
                    }
                }
            }
        }
    }

    .content-top-background {
        #content-top {
            margin-top : 50px;

            div.hwx-empfohlen-modules {
                div.hwx-empfohlen-items {
                    margin-bottom : 50px;

                    div.hwx-empfohlen-item {
                        div.hwx-empfohlen-item-body {
                            div.hwx-empfohlen-item-title {
                                text-align : left;
                            }

                            div.hwx-empfohlen-item-text {
                                text-align : left;
                            }

                            text-align : right;

                            a {
                                margin-top : 10px;
                            }
                        }
                    }
                }
            }

            .hwx-video-embed {
                height           : 100%;
                margin           : 50px auto;
                background-color : #e9ecef !important;
                padding          : 25px 0;

                div.embed-responsive {
                    width  : 50%;
                    margin : auto;
                    border : 1px solid black;

                    @include media-breakpoint-down(md) {
                        width : 100%;
                    }
                }
            }
        }
    }

    .content-main-background {
        .main-content-container {
            h1 {
                @include media-breakpoint-down(sm) {
                    font-size : 1.8rem;
                }
            }


            div.hwx-default-category-container {
                div.hwx-default-subcategories {
                    div.col-12 {
                        padding : 40px 15px;

                        div.hwx-default-subcategory-item {
                            div.hwx-default-subcategory-item-header {
                                height   : 80px;
                                overflow : hidden;

                                a {
                                    margin-bottom : 0;

                                    h2 {
                                        @include media-breakpoint-down(md) {
                                            font-size : 1.6rem;
                                        }
                                    }
                                }
                            }

                            div.hwx-default-subcategory-item-body {
                                div.hwx-default-subcategory-item-image {
                                }

                                div.hwx-default-subcategory-item-description {
                                }

                                div.hwx-default-subcategory-readmore {
                                    padding-left : 0;
                                    position     : absolute;
                                    bottom       : -6px;
                                    right        : 0;

                                    a {
                                        margin-right : 14px;
                                    }
                                }
                            }
                        }
                    }
                }

                div.hwx-default-categoryblock {
                    margin-bottom : 35px;

                    div.hwx-itemlist-image {
                        img {
                            width : 100%;
                        }
                    }

                    div.hwx-itemlist-category-header {
                        margin-top : 35px;
                    }
                }
            }


            #rs_read_this3 {
                #hwx-default-item {
                    .hwx-default-item-body {
                        .hwx-item-imageblock {
                            float : right;
                            @include media-breakpoint-down(md) {
                                float : unset;
                            }

                            .hwx-imageset {
                                .figure {
                                    a {
                                        img {
                                            padding-left   : 16px;
                                            padding-bottom : 16px;

                                            @include media-breakpoint-down(md) {
                                                padding-left   : 0;
                                                padding-bottom : 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #rs_read_this31 {
                #hwx-default-category {
                    .hwx-default-category-body {
                        .hwx-category-container {
                            margin-top : 10px;

                            .hwx-default-category-item-container {
                                .hwx-default-category-item-body {
                                    .hwx-category-text {
                                        .hwx-itemlist-header {
                                            h2 {
                                                a {
                                                    @include media-breakpoint-down(md) {
                                                        font-size : 1.6rem;
                                                    }
                                                }
                                            }
                                        }

                                        .hwx-imageset {
                                            .figure {
                                                float : left;
                                                @include media-breakpoint-down(md) {
                                                    float : unset;
                                                    width : 100%;
                                                }

                                                a {
                                                    img {
                                                        width          : 200px;
                                                        object-fit     : cover;
                                                        height         : 200px;
                                                        padding-right  : 16px;
                                                        padding-bottom : 16px;

                                                        @include media-breakpoint-down(md) {
                                                            width         : 100%;
                                                            padding-right : 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            #hwx-default-category {
                .hwx-default-category-body {
                    .hwx-category-container {
                        position : relative;

                        .hwx-default-category-item-container {
                            .hwx-default-category-item-body {
                                .hwx-itemlist-header {
                                    @include margin-bottom(15);
                                    height   : 60px;
                                    overflow : hidden;

                                    @include media-breakpoint-up(lg) {
                                        height : 80px;
                                    }

                                    h2 {
                                        margin-bottom : 0;

                                        a {
                                            /*
                                             * Das muss so sein, damit die Überschrift nach zwei Zeilen abgebrochen wird
                                             * Das overflow: hidden im elterncontainer ist ebenfalls wichtig.
                                             */
                                            display            : -webkit-box;
                                            -webkit-line-clamp : 2;
                                            -webkit-box-orient : vertical;

                                            @include media-breakpoint-down(md) {
                                                font-size : 1.6rem;
                                            }
                                        }
                                    }
                                }

                                .hwx-category-text {
                                    div.hwx-imageset {
                                        figure.figure {
                                            a {
                                                img {

                                                }
                                            }
                                        }
                                    }

                                    p {
                                        margin-top    : 16px;
                                        margin-bottom : 8px;

                                        a {
                                            font-weight : bold;
                                        }
                                    }
                                }

                                .hwx-category-readmore {
                                    @include margin-top(15);
                                }
                            }
                        }
                    }
                }
            }


            .hwx-text-tools {
                @include make-vertical-spacing(15);
                @include margin-bottom(30);
                display        : flex;
                flex-direction : column;
                align-items    : flex-end;

                .rsbtn {
                    padding-top    : 0;
                    padding-bottom : 0;

                    .rsbtn_play {
                        margin-bottom : 0;
                    }
                }

                .hwx-text-zoom {
                    text-align : right;

                    a {
                        color : $white;

                        @include media-breakpoint-down(sm) {
                            @include font-size-rem(12);
                        }

                        img {
                            height         : 22px;
                            padding-bottom : 4px;
                            padding-right  : 6px;
                        }
                    }
                }
            }
        }
    }

    .content-bottom-background {
        #content-bottom {

        }
    }

    #content-banner-bottom {

    }
}