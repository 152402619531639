#back-to-top {
    @include zindex(back-to-top);
    @include rem(top, $back-to-top-top);
    @include rem(right, $back-to-top-right);
    @include rem(bottom, $back-to-top-bottom);
    @include rem(left, $back-to-top-left);
    cursor           : pointer;
    position         : fixed;
    display          : none;
    background-color : $back-to-top-background;
    color            : $back-to-top-foreground;
    border-color     : $white;
}