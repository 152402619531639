.hwx-module-loader {
    .container-fluid {
        & > .row {
            &:first-child {
                & > .hwx-module-loader-modcol {
                    @include padding-top(50);
                    @include media-breakpoint-up(md) {
                        @include padding-top(75);
                    }

                    @include media-breakpoint-up(xl) {
                        @include padding-top(100);
                    }
                }
            }

            &:nth-child(2n + 1) {
                & > .hwx-module-loader-modcol {
                    &:first-child {
                        background-color : $gray-200;
                    }

                    &:last-child {
                        background-color : $white;
                    }
                }
            }

            &:last-child {
                & > .hwx-module-loader-modcol {
                    @include padding-bottom(50);
                    @include media-breakpoint-up(md) {
                        @include padding-bottom(75);
                    }

                    @include media-breakpoint-up(xl) {
                        @include padding-bottom(100);
                    }
                }
            }

            & > .hwx-module-loader-modcol {
                @include padding-top(30);
                @include padding-bottom(30);

                &:first-child {
                    background-color : $white;
                }

                &:last-child {
                    background-color : $gray-200;
                }
            }
        }
    }
}