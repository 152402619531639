.hwx-custom-module {
    .hwx-custom-module-container {
        @include padding(20);
        
        .hwx-custom-module-title {
            @include margin-bottom(30);
            
            .hwx-custom-module-title-text {
                font-weight : $font-weight-bold;
            }
        }
        
        .hwx-custom-module-content {
        
        }
    }
}