#hwx-lmdb-marketitem-detail {
    .hwx-lmdb-detail-item {
        .hwx-lmdb-detail-item-product .hwx-lmdb-detail-item-body .hwx-lmdb-detail-item-title .hwx-lmdb-detail-item-title-text {
            color : $primary;
        }

        .hwx-lmdb-detail-marketplace-provider .hwx-lmdb-detail-marketplace-provider-more .hwx-lmdb-detail-marketplace-provider-more-products .hwx-lmdb-detail-marketplace-provider-more-product .hwx-lmdb-detail-marketplace-provider-more-product-title {
            background-color : $primary;
            color            : $white;
        }
    }
}
