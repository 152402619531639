#hwx-catitem-boximgtitletext {
    .hwx-cat-category-block {
        .hwx-cat-category-title {
            .hwx-cat-category-title-text {
            
            }
        }
    }
    
    .hwx-cat-items {
        .hwx-catitem {
            @include margin-bottom(50);
            position : relative;
            
            .hwx-catitem-header {
                position     : absolute;
                top          : 0;
                left         : 0;
                right        : 0;
                width        : 50%;
                margin-left  : auto;
                margin-right : auto;
                
                .hwx-catitem-title {
                    margin : 0;
                    
                    a {
                        @include font-size-rem(24);
                        @include padding(15);
                        text-decoration  : none;
                        background-color : $primary;
                        color            : $secondary;
                        display          : block;
                        text-align       : center;
                        text-transform   : uppercase;
                    }
                }
            }
        }
    }
}