#hwx-lmdb-marketitem-detail.hwx-lmdb-detail-compact {
    @include padding-left($grid-gutter-width-half);
    @include padding-right($grid-gutter-width-half);
    background-color : $white;

    .hwx-lmdb-detail-item {
        .hwx-lmdb-detail-item-product {
            div.hwx-lmdb-detail-item-image {
                @include margin-bottom(30);

                div.hwx-lmdb-detail-item-imageblock {
                    img {
                        border-radius : 0;
                    }
                }
            }

            .hwx-lmdb-detail-item-body {
                .hwx-lmdb-detail-item-title-text {
                    @include font-size-rem(20);
                    color : $primary;
                }
            }
        }
    }

    .hwx-lmdb-detail-marketplace-provider {
        .hwx-lmdb-detail-marketplace-provider-more {
            .hwx-lmdb-detail-marketplace-provider-more-products {
                & > .row {
                    @include make-vertical-spacing();
                }

                .hwx-lmdb-detail-marketplace-provider-more-product {
                    .hwx-lmdb-detail-marketplace-provider-more-product-title {
                        background-color : $primary;

                        .hwx-lmdb-detail-marketplace-provider-more-product-title-text {
                            color : $white;
                        }
                    }
                }
            }
        }
    }
}

#hwx-lmdb-marketitems-list {
    &.hwx-lmdb-list-compact {
        $product-cols  : 3;
        $product-width : 100% / $product-cols;

        @include make-spacing(2);
        display        : flex;
        flex-wrap      : wrap;

        .hwx-lmdb-list-item {
            @include max-width($product-width);
            flex : 1 0 $product-width;

            .hwx-lmdb-list-item-content-title {
                @include height(50);
                @include padding(5);
                background-color : $gray-200;

                .hwx-lmdb-list-item-content-title-text {
                    @include font-size-rem(12);
                    margin-bottom : 0;
                    overflow      : hidden;

                    a {
                        color : $gray-900;
                    }
                }
            }

            .hwx-lmdb-list-item-image {
                @include height(175);
                position         : relative;
                background-color : $white;

                a {
                    display : block;
                    height  : 100%;

                    img {
                        height          : 100%;
                        width           : 100%;
                        object-fit      : cover;
                        object-position : center center;
                    }
                }

                div.price-eu-container {
                    @include padding(2);
                    text-align : right;
                    position   : absolute;
                    top        : 0;
                    right      : 0;
                    background : white;

                    span.price,
                    span.current-price {
                        @include font-size-rem(16);
                        @include rem(line-height, 20);
                        font-weight    : $font-weight-semibold;
                        font-style     : normal;
                        text-transform : none;
                    }

                    span.line-through-price {
                        text-decoration : line-through;
                    }

                    span.line-through-price {
                        @include font-size-rem(12);
                    }

                    span.line-through-eu,
                    span.eu {
                        //@include top(-5);
                        right    : 0;
                        position : relative;
                    }

                    span.line-through-eu {
                        @include font-size-rem(10);
                    }

                    span.eu {
                        @include font-size-rem(14);
                    }
                }
            }
        }
    }

    .hwx-lmdb-list-item {
        .hwx-lmdb-list-item-image {
            @include media-breakpoint-down(md) {
                height : 6.9375rem;
            }
        }
    }
}