// sass
// !default Werte werden nur dann verwendet, wenn keine Deklaration zuvor erfolgte:
// A.scss:
// $asd: 10px !default;
//
// wird von
// B.scss:
// $asd: 11px;
//
// überschrieben, wenn B vor A importiert wird
// http://sass-lang.com/documentation/file.SASS_REFERENCE.html#variable_defaults_default
// https://anotheruiguy.gitbooks.io/sassintherealworld_book-i/handy-tools/default-flag.html
// https://fontawesome.com/how-to-use/web-fonts-with-css
//

// CSS Imports
@import "jquery.fancybox.min.css";
@import "animate.min.css";
@import "awesomplete.min.css";
@import "owlcarousel/owl.carousel.min.css";
@import "owlcarousel/owl.theme.default.min.css";

// Fontawesome
$fa-font-path : "../fonts/fontawesome";
@import "_styles/core/fontSettings";

// Functions laden. Funktionen dürfen keine Variablen beinhalten, die erst später definiert werden. 
@import "_styles/core/helper/functions";

// Zum überschreiben der bootstrap Variablen entweder die Variablen aus bootstrap/_variables kopieren
// oder besser https://bootstrap.build/ verwenden
@import "_styles/overrides/config";
@import "_styles/overrides/fonts";

// third party Bibliotheken:
@import "bootstrap/bootstrap";
@import "font-awesome/fontawesome";
@import "font-awesome/regular";
@import "font-awesome/brands";
@import "font-awesome/solid";

// Core zuerst, dann overrides
@import "_styles/core/core";
@import "_styles/overrides/overrides";