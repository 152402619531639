header#header {
    .header-nav-top {

    }

    .header-main {
        @include padding-top(30);
        @include padding-bottom(30);

        .header-right-container {
            .mod-languages {
                .lang-inline {
                    li {
                        a {
                            img {
                                height : 20px;
                                border: 1px solid #e2e2e2;
                                box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
                            }
                        }
                    }
                }
            }

            .hwx-search {
                height          : 100%;
                display         : flex;
                align-items     : center;
                justify-content : flex-end;

                form {
                    display  : block;
                    width    : 100%;
                    position : relative;

                    input.search-input {
                        width : 100%;
                    }

                    .do-search {
                        @include rem(border-radius, 20);
                        right       : $input-padding-x;
                        position    : absolute;
                        top         : 0;
                        bottom      : 0;
                        line-height : 1;
                        border      : 0;
                        color       : $gray-600;
                    }
                }
            }
        }
    }

    .header-nav-bottom {
    }
}