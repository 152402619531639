nav#hwx-main-menu-head-nav {
    @include media-breakpoint-down(md) {
        @include padding-bottom(20);
    }

    .button-container {
        .navbar-toggler {

        }
    }

    .navbar-brand {

    }

    ul#hwx-main-menu-head-ul {
        & > li {
            & > a.nav-link {
                text-transform : uppercase;

                @include media-breakpoint-up(lg) {
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    height          : 100%;
                }
            }
        }

        li {
            &.active {
                a.nav-link {
                    background-color : $light-red;
                    color            : $white;
                }
            }

            a.nav-link {
                font-weight : $font-weight-semibold;
                color       : $red;

                @include media-breakpoint-up(lg) {
                    @include padding-top(20);
                    @include padding-bottom(20);
                }

                @include hover-focus-active {
                    background-color : $red;
                    color            : $white;
                }

                .sub-arrow {
                    display : none;
                }
            }

            ul.dropdown-menu {
                padding : 0;

                li {
                    a.nav-link {
                        @include padding-left(20);
                        @include padding-right(20);
                    }
                }
            }
        }
    }
}

.hwx-banner + nav#hwx-main-menu-banner-nav {
    @include media-breakpoint-up(lg) {
        position : absolute;
        left     : 0;
        right    : 0;
        bottom   : 0;
        z-index  : 1;
    }
}

nav#hwx-main-menu-banner-nav {
    padding-left  : 0;
    padding-right : 0;

    ul#hwx-main-menu-banner-ul {
        li {
            &:first-child {
                a.nav-link {
                    background-color : $red;
                    border-left      : 0;
                }
            }

            &:last-child {
                a.nav-link {
                    border-right : 0;
                }
            }

            a.nav-link {
                @include height($nav-link-height-xs);
                color            : $white;
                background-color : rgba($red, 0.7);
                border-left      : 1px solid $white;
                border-right     : 1px solid $white;
                display          : flex;
                justify-content  : center;
                align-items      : center;

                @include media-breakpoint-up(md) {
                    @include height($nav-link-height-md);
                    @include font-size-rem(24);
                }

                @include media-breakpoint-up(xl) {
                    @include height($nav-link-height-xl);
                }

                @include hover-focus-active {
                    color : darken($white, 10%);
                }

                svg,
                i {
                    @include font-size-rem(30);
                    @include margin-right(20);
                }
            }
        }
    }
}

nav#hwx-main-menu-foot-nav {
    padding-left  : 0;
    padding-right : 0;
    height        : 100%;

    ul#hwx-main-menu-foot-ul {
        li {
            &:first-child {
                a.nav-link {
                    padding-left : 0;
                }
            }

            a.nav-link {
                padding-top    : 0;
                padding-bottom : 0;
                color          : $white;

                @include hover-focus-active {
                    color : darken($white, 10%);
                }
            }
        }
    }
}