.hwx-module-loader {
    .container-fluid {
        & > .row {
            & > .hwx-module-loader-modcol {
                &:first-child {
                    .hwx-startseite-modules {
                        @include media-breakpoint-up(lg) {
                            @include padding-right($grid-gutter-width);
                            margin-left : auto;
                        }
                    }
                }

                &:last-child {
                    .hwx-startseite-modules {
                        @include media-breakpoint-up(lg) {
                            @include padding-left($grid-gutter-width);
                        }
                    }
                }
            }
        }
    }
}

.hwx-startseite-modules {
    @include media-breakpoint-only(lg) {
        @include rem(max-width, (map-get($container-max-widths, lg) / 2) - $grid-gutter-width);
    }

    @include media-breakpoint-up(xl) {
        @include rem(max-width, (map-get($container-max-widths, xl) / 2) - $grid-gutter-width);
    }

    .hwx-module-header {
        @include height(80);
        @include margin-bottom(15);
        @include padding-bottom(5);
        overflow : hidden;

        .hwx-module-header-text {
            border-bottom      : 1px solid $black;
            font-weight        : $font-weight-bold;
            /*
             * Das muss so sein, damit die Überschrift nach zwei Zeilen abgebrochen wird
             * Das overflow: hidden im elterncontainer ist ebenfalls wichtig.
             */
            display            : -webkit-box;
            -webkit-line-clamp : 2;
            -webkit-box-orient : vertical;
        }
    }

    .hwx-startseite-items {
        @include make-vertical-spacing($grid-gutter-width-half);

        .hwx-startseite-item {
            .hwx-imageset {
                @include margin-bottom(20);

                @include media-breakpoint-up(md) {
                    @include margin-bottom(30);
                }
            }

            .hwx-startseite-item-body {
                .hwx-startseite-item-title {
                    .hwx-startseite-item-title-text {
                        margin-bottom : 0;
                        font-weight   : $font-weight-bold;
                    }
                }

                .hwx-startseite-item-date {
                    @include margin-bottom(10);
                    @include font-size-rem(12);
                }

                .hwx-startseite-item-text {

                }
            }
        }
    }
}