.hwx-banner {
    .owl-carousel {
        .owl-stage {
            .owl-item {
                .item {
                    position : relative;

                    .hwx-carousel-caption {
                        @include margin-bottom(15);
                        position        : absolute;
                        bottom          : 0;
                        left            : 0;
                        right           : 0;
                        display         : flex;
                        justify-content : center;
                        align-items     : flex-end;
                        color           : $white;
                        text-shadow     : 0 0 20px $black;

                        @include media-breakpoint-only(sm) {
                            @include margin-bottom(30);
                        }

                        @include media-breakpoint-only(md) {
                            @include margin-bottom(45);
                        }

                        @include media-breakpoint-only(lg) {
                            @include margin-bottom(60);
                        }

                        @include media-breakpoint-up(xl) {
                            @include margin-bottom(75);
                        }

                        & > div {
                            display     : flex;
                            align-items : center;
                        }

                        .hwx-carousel-caption-content-title {
                            text-align : center;
                        }

                        .hwx-carousel-caption-content-text {

                        }

                        .hwx-carousel-caption-content-button {
                            @include margin-left(30);
                        }
                    }
                }
            }
        }

        .owl-nav {
            button {
                @include font-size-rem(32);
                @include margin-right(50);
                @include margin-left(50);
                position  : absolute;
                top       : 50%;
                transform : translateY(-50%);
                color     : $white;
                opacity   : 0.25;

                @include media-breakpoint-up(md) {
                    @include font-size-rem(48);
                    @include margin-right(100);
                    @include margin-left(100);
                }
            }

            .owl-prev {
                left : 0;
            }

            .owl-next {
                right : 0;
            }
        }

        .owl-dots {
            @include margin-bottom(25);
            position   : absolute;
            bottom     : 0;
            left       : 0;
            right      : 0;
        }
    }
}