.branchenverzeichnis {
    #hwx-filter-branche {
        .hwx-filter-label {
            @include margin-bottom(5);
            @include rem(letter-spacing, 1);
            font-weight    : $font-weight-bold;
            text-transform : uppercase;
        }

        .hwx-filter-button-container {
            @include margin(-2);
            display         : flex;
            flex-wrap       : wrap;
            justify-content : space-between;

            .filter-button {
                @include font-size-rem(14);
                @include margin(2);
                @include padding-top(3);
                @include padding-bottom(3);
                flex : 1 1 auto;

                &.active {
                    @extend .btn-primary;
                }
            }
        }
    }
}