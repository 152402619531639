.hwx-banner {
    .owl-carousel {
        .owl-stage-outer {
            .owl-stage {
                .owl-item {
                    .item {
                        .hwx-carousel-caption {
                            @extend .container-md;

                            @include media-breakpoint-only(lg) {
                                @include margin-bottom($nav-link-height-xl + 30);
                            }

                            @include media-breakpoint-up(xl) {
                                @include margin-bottom($nav-link-height-xl + 60);
                            }

                            .hwx-carousel-caption-content-text {
                                @include padding(20, 10);
                                @include font-size-rem(14);
                                background-color : rgba($black, 0.7);

                                @include headings {
                                    @include font-size-rem(28);
                                }

                                @include media-breakpoint-up(md) {
                                    @include padding(30, 15);
                                }

                                @include media-breakpoint-up(lg) {
                                    max-width : 75%;
                                }

                                @include media-breakpoint-up(xl) {
                                    @include padding(40, 20);
                                }
                            }
                        }
                    }
                }
            }
        }

        .owl-dots {
            margin-bottom : 0;

            @include media-breakpoint-only(md) {
                @include margin-bottom(10);
            }

            @include media-breakpoint-only(lg) {
                @include margin-bottom($nav-link-height-md + 15);
            }
            @include media-breakpoint-up(xl) {
                @include margin-bottom($nav-link-height-xl + 15);
            }
        }
    }
}