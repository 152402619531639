:root {
    --primary   : #{$primary};
    --secondary : #{$secondary};
    --black     : #{$black};
    --white     : #{$white};

    @each $color, $value in $grays {
        --gray-#{$color}: #{$value};
    }
}

html {
    position  : relative;
    height    : 100%;
    font-size : $base-font-size;

    body {
        position       : relative;
        height         : 100%;
        display        : flex;
        flex-direction : column;

        main {
            // IE11 möchte gern flex-basis auto statt 0
            // Den anderen Browsern scheint das Egal zu sein
            // Außerdem muss flex-shrink auf 0, damit der main
            // im IE11 nicht zusammenfällt
            flex : 1 0 auto;
        }
    }
}

h1 {
    font-weight : $font-weight-bold;
    @include margin-bottom(25);
}

h2 {
    @include margin-bottom(20);
}

h3 {
    @include margin-bottom(15);
}

@include headings {
    a {
        text-decoration : none;
    }
}

.btn {
    .btn-primary,
    .btn-secondary {
        text-decoration : none;
    }
}

.hwx-spacer {
    @include rem(margin-top, 30);
    @include rem(margin-bottom, 30);
    border-bottom : 2px solid rgba($black, 0.2);
    width         : 25%;
    margin-left   : auto;
    margin-right  : auto;

    &:last-of-type {
        display : none;
    }
}

.hwx-dont-break {
    white-space : nowrap;
}

#system-message-container {
    .alert {
        padding       : 0;
        margin-bottom : 0;
    }
}