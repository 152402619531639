#hwx-default-item {
    .hwx-default-item-body {
        @include clearfix;

        .hwx-item-imageblock {
            @include margin-bottom(30);
            text-align : center;

            @include media-breakpoint-only(lg) {
                @include margin-left(30);
                max-width : 40vw;
                float     : right;
            }

            @include media-breakpoint-up(xl) {
                max-width : 30vw;
            }
        }
    }

    .hwx-item-gallery {
        @include margin-top(50);
    }
}